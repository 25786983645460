import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PromotionImage from "../../images/lithium.jpg";

function Section4() {
  return (
    <>
      <section className="promotion_section">
        <Container>
          <Row className="align-items-center">
            <Col lg={6} className="text-center mb-5 mb-lg-0">
              <img src={PromotionImage} className="img-fluid" alt="Promotion" />
            </Col>
            <Col lg={6} className="px-5">
              <h2>GET THE BEST DESIGN WITH LATEST TECHNOLOGY</h2>
              <p>
               We specialize in creating cost-effective, cutting-edge designs tailored to your needs. Our expertise ensures that you receive the best solutions, combining the latest technology with efficient design to optimize your energy usage and reduce costs.
              </p>
            
                  <p>We are committed to transparency, providing clear pricing and a detailed breakdown of costs to build trust and demonstrate our dedication to value. Our personalized approach means we address your specific requirements and budget, ensuring that every aspect of the project aligns with your goals.</p>
              
                  <p>
                  Furthermore, our support doesn’t end with installation. We offer ongoing assistance to ensure your system operates seamlessly and continues to meet your needs. By integrating the latest advancements in renewable energy technology, we guarantee enhanced performance and long-term savings. Trust us to deliver a solution that maximizes efficiency, minimizes costs, and supports a sustainable future
                  </p>
              
            </Col>
          </Row>
        </Container>
      </section>

      {/* BG Parallax Scroll */}
    
    </>
  );
}

export default Section4;
