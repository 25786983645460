import React from "react";
import ShopHeader from "./ShopHeader";
import Footer from "./Footer";

function Layout({ children }) {
  return (
    <>
      <ShopHeader />
      <div>{children}</div>
      <Footer />
    </>
  );
}

export default Layout;
