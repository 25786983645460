import React from "react";
import ShopLayout from "../../components/Layouts/ShopLayout";
import "../../styles/HomeStyle.css";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Section7 from "./Section7";


const About = () => {
  return (
    <>
      <ShopLayout>
      
        <Section2 />
        <Section4 />
        <Section5/>
        <Section6 />
        <Section7 />
      </ShopLayout>
    </>
  );
};

export default About;
