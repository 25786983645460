import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import africell from "../../images/africell.jpeg";
import felicity from "../../images/felicity inv.jpeg";
import growatt from "../../images/growatt.jpg";
import sunfitPanel from "../../images/sunfit.jpeg";
import JinkoPanel from "../../images/jinko.jpeg";
import africellpanel from "../../images/africell panel.jpeg";
import luminiouswetcell from "../../images/luminious battery.jpeg";
import Mpowerwetcell from "../../images/mpower battery.jpeg";
import afripower_inverter from "../../images/afripower_inverter.jpeg";
import kartel  from "../../images/kartel inverter.jpeg";
import Felicity_lithium  from "../../images/lithium.jpeg";
import Mpower from "../../images/mpower inverter.jpeg";
import Prag  from "../../images/Prag_inverter.jpg";
import Felicity_lithium4 from "../../images/lithium.jpeg";
import Cards from "../../components/Layouts/Cards";
import { Link } from "react-router-dom";

// Mock Data Cards
const mockData = [
  {
    id: "0001",
    image: africell,
    title: "Africell Lithium Battery",
    paragraph: "Get this 10Kwh Premium Africell Lithium ion battery with 5 years warranty.",
    rating: 5,
    price: 99.15,
   
  },
  {
    id: "0002",
    image: felicity,
    title: "Felicity Inverter",
    paragraph: "Get this 10Kva felicity inverter with 1 years warranty Beautiful design Highly efficient battery Strong and long lasting",
    rating: 4.5,
    price: 1.1,
   
  },
  {
    id: "0003",
    image: growatt,
    title: "Growatt Inverter",
    paragraph: "Get this 5kva Inverter",
    rating: 4,
    price: 69.15,  
   
  },
  {
    id: "0004",
    image: sunfitPanel,
    title: "Sunfit Solar Panel",
    paragraph: "Get this 5kva Inverter",
    rating: 3.5,
    price: 99.25,
   
  },
  {
    id: "0005",
    image: JinkoPanel,
    title: "Jinko Solar Panel",
    paragraph: "call us for the current price",
    rating: 3.0,
    price: 59.25,
    
  },
  {
    id: "0006",
    image: africellpanel,
    title: "Africell Panel",
    paragraph: "call us for the current price",
    rating: 3,
    price: 79.18,
    
  },
  {
    id: "0007",
    image: luminiouswetcell,
    title: "Luminious Tubular battery",
    paragraph: "call us for the current price",
    rating: 2.5,
    price: 99.19,
    
  },
  {
    id: "0008",
    image: Mpowerwetcell,
    title: "Mpower Tubular",
    paragraph: "call us for the current price",
    rating: 2.0,
    price: 89.12,
    
  },
{
  id: "0009",
  image: Felicity_lithium,
  title: "Felicity Lithium",
  paragraph: "Get this 10Kwh Premium Africell Lithium ion battery with 5 years warranty.",
  rating: 5,
  price: 99.15,
 
},
{
  id: "0010",
  image: kartel ,
  title: "Kartel  Inverter",
  paragraph: "Get this 10Kva felicity inverter with 1 years warranty Beautiful design Highly efficient battery Strong and long lasting",
  rating: 4.5,
  price: 1.1,
 
},
{
  id: "0011",
  image: afripower_inverter,
  title: "Afripower inverter",
  paragraph: "Get this 5kva Inverter",
  rating: 4,
  price: 69.15,  
 
},
{
  id: "0012",
  image: Mpower,
  title: "Mpower Inverter",
  paragraph: "Get this 5kva Inverter",
  rating: 3.5,
  price: 99.25,
 
},
{
  id: "0013",
  image: Prag,
  title: "Prag Inverter",
  paragraph: "call us for the current price",
  rating: 3.0,
  price: 59.25,
  
},
{
  id: "0014",
  image: africellpanel,
  title: "Africell Panel",
  paragraph: "call us for the current price",
  rating: 3,
  price: 79.18,
  
},
{
  id: "0015",
  image: luminiouswetcell,
  title: "Luminious Tubular battery",
  paragraph: "call us for the current price",
  rating: 2.5,
  price: 99.19,
  
},
{
  id: "0016",
  image: Mpowerwetcell,
  title: "Mpower Tubular battery",
  paragraph: "call us for the current price",
  rating: 2.0,
  price: 89.12,
},
  // Add more mock data objects as needed
];

// Rating Logical Data
const renderRatingIcons = (rating) => {
  const stars = [];

  for (let i = 0; i < 5; i++) {
    if (rating > 0.5) {
      stars.push(<i key={i} className="bi bi-star-fill"></i>);
      rating--;
    } else if (rating > 0 && rating < 1) {
      stars.push(<i key={"half"} className="bi bi-star-half"></i>);
      rating--;
    } else {
      stars.push(<i key={`empty${i}`} className="bi bi-star"></i>);
    }
  }
  return stars;
};

function ShopSection3() {
  return (
    <section className="menu_section">
      <Container>
        <Row>
          <Col lg={{ span: 8, offset: 2 }} className="text-center mb-5">
            <h2>Our Store</h2>
            <p className="para">
               </p>
          </Col>
        </Row>
        <Row>
          {mockData.map((cardData, index) => (
            <Cards
              key={index}
              image={cardData.image}
              rating={cardData.rating}
              title={cardData.title}
              paragraph={cardData.paragraph}
              //price={cardData.price}
             
              renderRatingIcons={renderRatingIcons}
            />
          ))}
        </Row>
      
       
      </Container>
    </section>
  );
}

export default ShopSection3;
