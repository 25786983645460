import React from "react";
import ShopLayout from "../../components/Layouts/ShopLayout";
import "../../styles/ShopStyle.css";

import ShopSection3 from "./ShopSection3";
import ShopSection7 from "./ShopSection7";


const Shop = () => {
  return (
    <>
      <ShopLayout>
        <ShopSection3 />
        <ShopSection7 />
      </ShopLayout>
    </>
  );
};

export default Shop;
