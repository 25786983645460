import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

function Section7() {
  return (
    <section className="contact_section">
      <Container>
        
        <Row className="justify-content-center">
          <Col sm={8} className="text-center">
            <h4>We sell and Guarantee</h4>
            <h2>Nationwide Delivery!</h2>
            <p>
             Place your order and get immediate delivery 
            </p>
            <Link to="/" className="btn btn_red px-4 py-2 rounded-0">
              Call: 08-143-042-627
            </Link>
          </Col>
        </Row>
        
      </Container>
    </section>
  );
}

export default Section7;
